import YAML from 'yaml'
const StellarSdk = require('stellar-sdk')
const { Octokit } = require('@octokit/core')

const API_TOKEN = process.env.REACT_APP_GITHUB_API_TOKEN
let octokit
if (API_TOKEN) {
  octokit = new Octokit({
    auth: API_TOKEN
  })
} else {
  octokit = new Octokit()
}

export const getAddresses = async () => {
  console.log('Running `getAddresses()`')
  let res = await octokit.request('GET /repos/{owner}/{repo}/contents/{path}', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    path: 'ADDRESSES.yml'
  })
  return YAML.parse(atob(res.data.content))
}

export const getOpenIssues = async () => {
  console.log('Running `getOpenIssues()`')
  let res = await octokit.request('GET /repos/{owner}/{repo}/issues', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    state: 'open'
  })
  let returnObj = {}
  res.data.forEach((item, i) => {
    if (!item.pull_request) {
      returnObj[item.number] = [ item.title, item.labels[0].name ]
    }
  });
  return returnObj
}

export const getIssueAuthor = async (issueNumber) => {
  console.log('Running `getIssueAuthor()`')
  let res = await octokit.request('GET /repos/{owner}/{repo}/issues/{issue_number}', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    issue_number: issueNumber
  })
  let username = res.data.user.login
  return username
}

export const getIssueCommenters = async (issueNumber) => {
  console.log('Running `getIssueCommenters()`')
  let res = await octokit.request('GET /repos/{owner}/{repo}/issues/{issue_number}/comments', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    issue_number: issueNumber
  })
  let comments = res.data
  let reviewers = []
  if (comments.length > 0) {
    comments.forEach(item => {
      reviewers.push(item.user.login)
    })
    return [...new Set(reviewers)]
  } else {
    return []
  }
}

export const getParameterCaseInsensitive = (object, key) => {
  return object[Object.keys(object).filter(function(k) {
    return k.toLowerCase() === key.toLowerCase();
  })[0]];
}

export const getNextSequenceNumber = async (sourcePublicKey) => {
  // let server = new StellarSdk.Server('https://horizon-testnet.stellar.org')
  let server = new StellarSdk.Server('https://horizon.stellar.org')
  let account = await server.loadAccount(sourcePublicKey)
  account.incrementSequenceNumber()
  return account.sequence
}

export const generateXDR = (stateObject, setStateMethod) => {
  const networkPassphrase = StellarSdk.Networks.PUBLIC
  // const networkPassphrase = StellarSdk.Networks.TESTNET
  let seqMinusOne = StellarSdk.UnsignedHyper.fromString(stateObject.sequence_number).subtract(1).toString()
  let sourceAccount = new StellarSdk.Account(stateObject.source, seqMinusOne)

  let transaction = new StellarSdk.TransactionBuilder(
    sourceAccount,
    {
      fee: '10000',
      networkPassphrase: networkPassphrase,
      memo: new StellarSdk.Memo.text(stateObject.memo)
    }
  )
  .setTimeout(0)

  if (stateObject.hunter !== '') {
    transaction.addOperation(StellarSdk.Operation.payment({
      destination: stateObject.hunter,
      asset: StellarSdk.Asset.native(),
      amount: stateObject.rewards.hunter.toString(),
    }))

    if (stateObject.reviewers.length > 0) {
      for (let i = 0; i < stateObject.reviewers.length; i++) {
        transaction.addOperation(StellarSdk.Operation.payment({
          destination: stateObject.reviewers[i],
          asset: StellarSdk.Asset.native(),
          amount: stateObject.rewards.reviewer.toString(),
        }))
      }
    }
    setStateMethod(transaction.build().toXDR())
  } else {
    setStateMethod('waiting for bounty hunter selection')
  }

}

export const getRewardAmounts = async (issueNumber) => {
  console.log('Running `getRewardAmounts()`')
  const specRegex = /.*\/(bounties\/level-\d\/.*\.md)/
  const rewardRegex = /\|\s(\d+)\s?XLM.*\|\s(\d+)\s?XLM/
  let res = await octokit.request('GET /repos/{owner}/{repo}/issues/{issue_number}', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    issue_number: issueNumber
  })
  let issueBody = res.data.body
  let bountySpecPath = issueBody.match(specRegex)[1]
  let specRes = await octokit.request('GET /repos/{owner}/{repo}/contents/{path}', {
    owner: 'tyvdh',
    repo: 'stellar-quest-bounties',
    path: bountySpecPath
  })

  let specFile = atob(specRes.data.content)
  let rewards = specFile.match(rewardRegex)
  return rewards !== null ? [ rewards[1], rewards[2] ] : [ false, false ]
}

export const importTransaction = async (xdrString) => {
  console.log('Running `getTransactionFromXdr()`')

  let transactionState = {
    sequence_number: '',
    memo: '',
    hunter: '',
    reviewers: [],
    rewards: {
      hunter: '',
      reviewer: '',
    }
  }

  const networkPassphrase = StellarSdk.Networks.PUBLIC
  // const networkPassphrase = StellarSdk.Networks.TESTNET
  let transaction = new StellarSdk.Transaction(xdrString, networkPassphrase)
  transactionState.sequence_number = transaction._sequence
  transactionState.memo = new TextDecoder().decode(transaction._memo._value)
  let issueNumber = transactionState.memo.substring(4)
  transaction._operations.map((item, i) => {
    if (i === 0) {
      transactionState.hunter = item.destination
      transactionState.rewards.hunter = parseInt(item.amount).toString()
    } else {
      transactionState.reviewers.push(item.destination)
      transactionState.rewards.reviewer = parseInt(item.amount).toString()
    }
    return item
  })
  return [ issueNumber, transactionState ]
}
