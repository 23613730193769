export default function HunterSelector(props) {
  let addresses = props.addresses

  const makeHunterOptions = (addresses) => {
    let renderArr = []
    for (const [key, value] of Object.entries(addresses)) {
        renderArr.push(<option key={key} value={value}>{key}: {value}</option>)
      }
      return renderArr
  }

  const handleSelection = (event) => {
    props.setBountyState({ type: 'set_hunter', hunter: event.target.value })
  }

  return (
    <div className="mb-3">
      <label htmlFor="hunterSelector" className="form-label">Select Bounty Hunter</label>
      <select onChange={handleSelection} value={props.hunter} id="hunterSelector" className="form-select" aria-label="Select bounty hunter">
        <option>Known addresses...</option>
        { makeHunterOptions(addresses) }
      </select>
    </div>
  )
}
