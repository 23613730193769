import './DisplayXDR.css'
import albedo from '@albedo-link/intent'

export default function DisplayXDR(props) {
  const xdr = props.xdr

  const selectXdrText = () => {
    let obj = document.getElementById('XDRTextAgain')
    obj.select()
  }

  const submitWithAlbedo = async (event) => {
    event.preventDefault()
    let xdr = event.target[0].value
    console.log(xdr)
    try {
      let res = await albedo.tx({
        xdr: xdr,
        submit: true,
        network: 'public',
      })
      console.log(res)
      if (res.result.successful) {
        props.setHash(res.tx_hash)
        props.setXDR(res.signed_envelope_xdr)
      } else {
        throw `Transaction Unsuccessful! ${res.result.result_xdr}`
      }
    } catch (e) {
      props.setError(`${e}`)
      console.error(e)
    }
  }

  return (
    <form onSubmit={submitWithAlbedo}>
      <div className="mb-3">
        <label htmlFor="XDRTextAgain" className="form-label">Transaction XDR</label>
        <textarea className="form-control bg-light border-0 user-select-all font-monospace" onFocus={selectXdrText} id="XDRTextAgain" rows="15" value={xdr} aria-label="Transaction XDR" readOnly></textarea>
      </div>
      <button type="submit" className="btn btn-primary mb-3">Submit with Albedo</button>
    </form>
  )
}
