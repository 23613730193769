"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerApi = void 0;
var ServerApi;
(function (ServerApi) {
    var TradeType;
    (function (TradeType) {
        TradeType["all"] = "all";
        TradeType["liquidityPools"] = "liquidity_pool";
        TradeType["orderbook"] = "orderbook";
    })(TradeType = ServerApi.TradeType || (ServerApi.TradeType = {}));
})(ServerApi = exports.ServerApi || (exports.ServerApi = {}));
