export const initialState = {
  addresses: {},
  // source: 'GBIKUA6NCMQ7HF4FF67MQ5SD3FXOSUQUCUKWKYCBG2OTH7OWXHOFOVYQ', // for testnet
  source: 'GBBTG4HYFMFTGNUCRFA6GQ5MBHNM43VCQB2YR6DYZRTK5FLR54YHXXXC',
  sequence_number: '0',
  memo: 'SQB#',
  issue: '',
  issue_url: 'https://github.com/tyvdh/stellar-quest-bounties/issues',
  title: '',
  hunter: '',
  reviewers: [],
  rewards: {
    hunter: '1',
    reviewer: '1'
  },
  xdr: 'hereissomexdr',
  issues: {}
}
