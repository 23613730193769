import React, { useReducer, useEffect, useState } from 'react';
import reducer from './reducer';
import { initialState } from './initialState';
import {
  getAddresses,
  getOpenIssues,
  getNextSequenceNumber,
  generateXDR,
  importTransaction } from '../../lib/utils'
import PayoutForm from '../PayoutForm/PayoutForm'
import DisplayXDR from '../DisplayXDR/DisplayXDR'
import DisplayHash from '../DisplayHash/DisplayHash'
// import ImportXDR from '../ImportXDR/ImportXDR'
import './App.css';

function App() {
  const [ bountyState, setBountyState ] = useReducer(reducer, initialState)
  let [ transactionXDR, setXDR ] = useState('')
  let [ transactionHash, setHash ] = useState('')
  let [ errorMessage, setError ] = useState('')

  useEffect(() => {
    const populateAddresses = async () => {
      let addresses = await getAddresses()
      setBountyState({ addresses: addresses, type: 'populate_addresses' })
    }
    populateAddresses()

    const populateIssues = async () => {
      let issues = await getOpenIssues()
      setBountyState({ issues: issues, type: 'populate_issues' })
    }
    populateIssues()

    const setNextSequenceNumber = async () => {
      let seqNumber = await getNextSequenceNumber(bountyState.source)
      setBountyState({ type: 'set_sequence', sequence_number: seqNumber })
    }

    const importXDR = async (xdrString) => {
      let [ issueNumber, transactionState ] = await importTransaction(xdrString)
      setBountyState({ type: 'set_issue', issue: issueNumber })
      setBountyState({ transactionState: transactionState, type: 'import_xdr' })
    }

    let urlXDR = decodeURIComponent(window.location.pathname).substring(1)
    if (urlXDR) {
      importXDR(urlXDR)
    } else {
      setNextSequenceNumber()
    }
  }, [])

  useEffect(() => {
    generateXDR(bountyState, setXDR)
  }, [ bountyState ])

  return (
    <div className="App">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <PayoutForm bountyState={bountyState}
                        setBountyState={setBountyState} />
          </div>
          <div className="col-xs-12 col-md-6">
            <DisplayXDR xdr={transactionXDR}
                        setXDR={setXDR}
                        setHash={setHash}
                        setError={setError} />
            <DisplayHash transactionHash={transactionHash} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
