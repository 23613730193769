import './DisplayHash.css'

export default function DisplayHash(props) {

  const selectHashText = () => {
    let obj = document.getElementById('txHash')
    obj.select()
  }

  return (
    <form>
      <div className="mb-3">
        <label htmlFor="txHash" className="form-label">Transaction Hash</label>
        <input type="text" className="form-control bg-light border-0 user-select-all font-monospace" onFocus={selectHashText} id="txHash" value={props.transactionHash} readOnly />
      </div>
    </form>
  )
}
