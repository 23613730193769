import { initialState } from './initialState'


export default function reducer( state = initialState, action ) {
  let newState = {...state}
  switch ( action.type ) {
    case 'import_xdr':
      newState = {
        ...newState,
        ...action.transactionState
      }
      return newState
    case 'populate_addresses':
      newState.addresses = action.addresses
      return newState
    case 'populate_issues':
      newState.issues = action.issues
      return newState
    case 'set_issue':
      newState.issue = action.issue
      newState.issue_url = `https://github.com/tyvdh/stellar-quest-bounties/issues/${action.issue}`
      newState.memo = `SQB#${action.issue}`
      return newState
    case 'set_hunter':
      newState.hunter = action.hunter
      return newState
    case 'add_reviewer':
      newState.reviewers = [...newState.reviewers, action.reviewer]
      return newState
    case 'remove_reviewer':
      let i = newState.reviewers.indexOf(action.reviewer)
      if (i > -1) {
        newState.reviewers.splice(i, 1)
      }
      return newState
    case 'clear_reviewers':
      newState.reviewers = []
      return newState
    case 'set_xdr':
      newState.xdr = action.xdr
      return newState
    case 'add_reward':
      newState.rewards[action.recipient] = action.amount
      return newState
    case 'set_sequence':
      newState.sequence_number = action.sequence_number
      return newState
    case 'reset_everything':
      return state
    default:
      return state
  }
}
