export default function SequenceNumber(props) {

  const handleChange = (event) => {
    props.setBountyState({ type: 'set_sequence', sequence_number: event.target.value })
  }

  return (
    <div className="mb-3">
      <label htmlFor="sequenceNumber" className="form-label">Sequence Number</label>
      <input onChange={handleChange} type="number" className="form-control" id="sequenceNumber" name="sequenceNumber" value={props.sequence_number} />
      <div className="form-text">ACTION REQUIRED: Check and edit the sequence number carefully. The transaction will be built with the sequence number shown above.</div>
    </div>
  )
}
