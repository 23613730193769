import {
  getIssueAuthor,
  getParameterCaseInsensitive,
  getIssueCommenters,
  getRewardAmounts
} from '../../lib/utils'

export default function IssueSelector(props) {
  let issues = props.issues
  const makeIssueOptions = (issues) => {
    let renderArr = []
    for (const [key, value] of Object.entries(issues)) {
      if (value[1] === 'award') {
        renderArr.push(<option key={key} value={key} style={{ fontWeight: "bold" }}data-bounty-status={value[1]}>{key}: {value[0]}</option>)
      } else {
        renderArr.push(<option key={key} value={key}>{key}: {value[0]}</option>)
      }
    }
    return renderArr
  }

  const handleSelection = (event) => {
    let issueNumber = event.target.value
    props.setBountyState({ type: 'set_issue', issue: issueNumber })
    getIssueAuthor(issueNumber).then((author) => {
      let hunterAddress = getParameterCaseInsensitive(props.addresses, author)
      props.setBountyState({ type: 'set_hunter', hunter: hunterAddress })
      props.setBountyState({ type: 'clear_reviewers' })
      return hunterAddress
    }).then((hunterAddress) => {
      getIssueCommenters(issueNumber).then((commenters) => {
        // console.log(commenters)
        commenters.forEach(item => {
          let reviewerAddress = getParameterCaseInsensitive(props.addresses, item)
          if (reviewerAddress !== hunterAddress) {
            props.setBountyState({ type: 'add_reviewer', reviewer: reviewerAddress })
          }
        })
        return issueNumber
      }).then((issueNumber) => {
        getRewardAmounts(issueNumber).then(([ hunter, reviewer ]) => {
          props.setBountyState({ type: 'add_reward', recipient: 'hunter', amount: hunter ? hunter : '1' })
          props.setBountyState({ type: 'add_reward', recipient: 'reviewer', amount: reviewer ? reviewer : '1' })
        })
      })
    })
  }

  return (
    <div className="mb-3">
      <label htmlFor="issueSelector" className="form-label">Select Bounty Issue</label>
      <select onChange={handleSelection} value={props.issue} id="issueSelector" className="form-select" aria-label="Select bounty issue">
        <option>Currently open issues...</option>
        { makeIssueOptions(issues) }
      </select>
      <div className="form-text">Issue URL: <a href={props.issue_url}>{props.issue_url}</a></div>
    </div>
  )
}
