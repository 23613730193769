export default function ReviewerSelector(props) {
  let addresses = props.addresses

  const makeReviewerOptions = (addresses) => {
    let renderArr = []
    for (const [key, value] of Object.entries(addresses)) {
      renderArr.push(<div key={`reviewer-${key}-div`} className="form-check form-check-inline">
                       <input onChange={handleChange} className="form-check-input" type="checkbox" key={value} value={value} id={`reviewer-${key}`} checked={props.reviewers.includes(value)} />
                       <label className="form-check-label" htmlFor={`reviewer-${key}`} key={`reviewer-${key}-label`}>{key}</label>
                     </div>)
    }
    return renderArr
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      props.setBountyState({ type: 'add_reviewer', reviewer: event.target.value })
    } else {
      props.setBountyState({ type: 'remove_reviewer', reviewer: event.target.value })
    }
  }

  return (
    <div className="mb-3">
      <p className="form-label">Select Bounty Reviewer(s)</p>
      { makeReviewerOptions(addresses) }
    </div>
  )
}
