export default function ReviewerReward(props) {

  const handleChange = (event) => {
    props.setBountyState({ type: 'add_reward', recipient: 'reviewer', amount: event.target.value })
  }

  return (
    <div className="mb-3">
      <label htmlFor="reviewerReward" className="form-label">Reviewer Reward</label>
      <input onChange={handleChange} value={props.reward} type="number" className="form-control" id="reviewerReward" />
    </div>
  )
}
