import './PayoutForm.css'
import IssueSelector from './IssueSelector'
import HunterSelector from './HunterSelector'
import ReviewerSelector from './ReviewerSelector'
import HunterReward from './HunterReward'
import ReviewerReward from './ReviewerReward'
import TransactionMemo from './TransactionMemo'
import SequenceNumber from './SequenceNumber'

export default function PayoutForm(props) {

  const resetEverything = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  return(
    <form>
      <IssueSelector issues={props.bountyState.issues}
                     issue={props.bountyState.issue}
                     issue_url={props.bountyState.issue_url}
                     addresses={props.bountyState.addresses}
                     setBountyState={props.setBountyState} />
      <HunterSelector addresses={props.bountyState.addresses}
                      hunter={props.bountyState.hunter}
                      setBountyState={props.setBountyState} />
      <HunterReward reward={props.bountyState.rewards.hunter}
                    setBountyState={props.setBountyState} />
      <ReviewerSelector addresses={props.bountyState.addresses}
                        reviewers={props.bountyState.reviewers}
                        setBountyState={props.setBountyState} />
      <ReviewerReward reward={props.bountyState.rewards.reviewer}
                      setBountyState={props.setBountyState} />
      <TransactionMemo memo={props.bountyState.memo}
                       setBountyState={props.setBountyState} />
      <SequenceNumber sequence_number={props.bountyState.sequence_number}
                      setBountyState={props.setBountyState} />
      <button onClick={resetEverything} type="button" className="btn btn-danger">Reset Everything</button>
    </form>
  )

}
